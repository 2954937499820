<template>
  <div class="form-box login-box" v-loading="loading">
    <!-- :style="{ backgroundImage: 'url(' + bigSrc + ')' }" -->
    <div class="img-bg">
      <img v-lazy="bigSrc" v-if="bigSrc"/>
    </div>
    <div class="wrapper">
      <!-- <div class="logo">
        <el-image
          class="default-img"
          :src="teamsInfo.avatar"
          fit="cover"
          v-if="teamsInfo.avatar"
        ></el-image>
        <img class="default-img" src="../../assets/img/login/logo.png" />
      </div> -->
      <!-- <div class="page-title" v-if="teamsInfo.teamsName">
        {{ teamsInfo.teamsName }}
      </div> -->
      <!-- <div class="page-title" v-else>临床科研大数据平台</div> -->
      <div class="detail-con">
        <div class="left-con">
          <!-- <img src="@/assets/img/login/left-img.png" v-if="!teamsInfo.avatar " /> -->
        </div>
        <!-- <div class="left-con"></div> -->
        <div class="form-con" id="form-con">
          <div class="form-title">用户登录</div>
          <el-form
            :model="dataForm"
            :rules="dataRule"
            ref="dataForm"
            @keyup.enter.native="dataFormSubmit()"
          >
            <el-form-item prop="account">
              <el-input v-model="dataForm.account" placeholder="账户">
                <i slot="prefix" style="display: flex; align-items: center">
                  <img src="../../assets/img/user.png" alt />
                </i>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                v-model="dataForm.password"
                placeholder="密码"
              >
                <i slot="prefix" style="display: flex; align-items: center">
                  <img src="../../assets/img/pwd.png" alt />
                </i>
              </el-input>
            </el-form-item>
            <el-form-item class="remember-pwd" style="line-height: 0">
              <el-checkbox v-model="checked">记住密码</el-checkbox>
            </el-form-item>
          </el-form>
          <div>
            <el-button class="submit-btn" type="primary" @click="getCaptcha"
              >登录</el-button
            >
          </div>
        </div>
      </div>
      <div class="footer" v-if="teamsInfo.id">
        <!-- <div>
          ©Copyright {{ currentYear }} {{ teamsInfo.deptName }} All Rights
          Reserved.<span class="support">纳实医学 提供技术支持</span>
        </div> -->
        <div>
          {{ teamsInfo.bottomStatement }}
        </div>
      </div>
      <div class="footer" v-else>
        ©Copyright 2020-{{ currentYear }} 南京纳实医学科技有限公司 All Rights
        Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import Qs from "qs";
var regPhone = /^1[34578]\d{9}$/,
  regPwd = /^[a-z|A-Z|0-9|\-|_]{4,20}$/;
import { getUUID } from "../../utils/index";
import MD5 from "js-md5";
// 腾讯滑块验证(新建一个js文件)
import TencentCaptcha from "@/assets/TencentCaptcha";
const Base64 = require("js-base64").Base64;
export default {
  data() {
    /*var validatePhone = (rule, value, callback) => {
        if (!value) {
          callback(new Error('请输入手机号'));
        } else if (!regPhone.test(value)) {
          callback(new Error('手机号格式不正确'));
        } else {
          callback();
        }
      }*/
    var validatePwd = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      } /*else if (!regPwd.test(value)){
          callback(new Error('4-20位，字母、数字或_'));
        }*/ else {
        callback();
      }
    };
    return {
      usernameText: "", //用户名文本
      passwordText: "", //记住密码文本
      dataForm: {
        account: "",
        password: "",
        //codeImg: ''
      },
      dataRule: {
        account: [
          { required: true, message: "请输入用户名/手机号", trigger: "blur" },
        ],
        password: [{ required: true, validator: validatePwd, trigger: "blur" }],
        code: [
          { required: true, message: "请输入图形验证码", trigger: "blur" },
        ],
      },
      userInfo: {},
      loading: false,
      uuid: "",
      appId: "2036675646",
      // doctorUnionId: "00ec8279761b4ea78697c182db4e7877",
      unionName: "",
      checked: false,
      currentYear: new Date().getFullYear(),
      teamsInfo: "",
      bigSrc: "",
    };
  },
  computed: {},
  mounted() {
    localStorage.currentTitle = "多中心临床科研大数据平台-纳实医学";
    document.title = "多中心临床科研大数据平台-纳实医学";
    // 在页面加载时从cookie获取登录信息
    let account = this.getCookie("account");
    let password = Base64.decode(this.getCookie("password"));
    // 如果存在赋值给表单，并且将记住密码勾选
    if (account) {
      this.dataForm.account = account;
      this.dataForm.password = password;
      this.checked = true;
    }
    if (this.$route.query.teamsId) {
      this.deptId = this.$route.query.teamsId;
      localStorage.loginTeamId = this.deptId;
      this.getTeamsInfo();
    } else {
      this.bigSrc = require("@/assets/img/login/bg3.png");
      localStorage.removeItem("loginTeamId");
      
    }
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      // 监听浏览器窗口大小变化，调整表单布局
      const form = document.getElementById("form-con");
      console.log("form", form);
      const formWidth = form.offsetWidth;
      const formHeight = form.offsetHeight;
      const browserWidth = window.innerWidth;
      const browserHeight = window.innerHeight;
      if (formWidth > browserWidth) {
        form.style.width = `${browserWidth}px`;
      }
      if (formHeight > browserHeight) {
        form.style.height = `${browserHeight}px`;
      }
    },
    // 获取滑块验证码
    getCaptcha() {
      let _this = this;

      _this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          this.$Captcha.show();
          this.$bus.$on("getTicket", (res) => {
            // 返回结果
            // ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
            // ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
            // CaptchaAppId       String    验证码应用ID。
            // bizState    Any       自定义透传参数。
            if (res.ret === 0) {
              let params = {
                randstr: res.randstr,
                ticket: res.ticket,
              };
              _this
                .$http({
                  url: _this.$http.adornUrl("/bind/describeCaptchaResult"),
                  method: "post",
                  data: Qs.stringify(params),
                })
                .then(({ data }) => {
                  let captchaCode = JSON.parse(data.data).CaptchaCode;
                  if (data.data && captchaCode === 1) {
                    _this.dataFormSubmit();
                  } else {
                    _this.$message.error("滑块校验未通过，请重新尝试");
                  }
                });
            } else {
              // 提示用户完成验证
            }
            this.$bus.$off("getTicket");
          });
        }
      });
    },
    // 提交表单
    dataFormSubmit() {
      // 判断复选框是否被勾选; 勾选则调用配置Cookie方法
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          let param = {
            stringParam1: this.dataForm.account,
            stringParam2: MD5(this.dataForm.password),
            intParam1: 1,
            stringParam3: this.doctorUnionId,
          };
          localStorage.removeItem("token");
          this.$httpAes({
            url: this.$httpAes.adornUrl("/organBusiness/login"),
            method: "post",
            data: param,
          }).then(({ data }) => {
            if (data.status) {
              localStorage.userInfo = JSON.stringify(data.data.user);
              localStorage.dept = JSON.stringify(data.data.dept);
              localStorage.setItem("token", data.data.token);
              this.$cookie.set("hospitalId", data.data.user.deptPid);
              if (
                data.data.teamsManages &&
                data.data.teamsManages.length == 0
              ) {
                this.$message.error(
                  "当前工作室团队状态异常，请联系系统管理员。"
                );
                return;
              }
              this.getGzsInfo();
              this.setUserInfo();
            } else {
              this.$message.error(data.msg);
            }
          });
        }
      });
    },
    // 获取团队下的病种
    getGzsInfo() {
      let that = this;
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/organBusiness/info"),
          method: "post",
		  data: {},
        })
        .then(({ data }) => {
          if (data.data) {
            this.$store.commit("common/updateImageUrl", data.data.avatar);
            sessionStorage.organBusinessInfo = JSON.stringify(data.data);
            if (data.data.teamsManages && data.data.teamsManages.length) {
              that.teamsManages = data.data.teamsManages;
              localStorage.teamsManages = JSON.stringify(that.teamsManages);
              if (data.data.lastTeamId) {
                that.currentTeam = that.teamsManages.find((item) => {
                  return item.id == data.data.lastTeamId;
                });
                localStorage.currentTeam = JSON.stringify(that.currentTeam);
                //that.currentTeam = JSON.parse(localStorage.currentTeam);
              } else {
                that.currentTeam = data.data.teamsManages[0];
                localStorage.currentTeam = JSON.stringify(
                  data.data.teamsManages[0]
                );
              }
              this.showTeam = true;
              this.gzsFlag = true;

              localStorage.gzsFlag = true;
              this.$router.push({ name: "home" });
              sessionStorage.loginType = "login";
            } else if (data.data.roleId == "e89325119fa411e89e43525400557296") {
              console.log("没工作室+管理员身份");
              sessionStorage.radio = 1;
              sessionStorage.loginType = "login";
              this.$router.push({ name: "home" });
            } else if (data.data.roleId != "e89325119fa411e89e43525400557296") {
              localStorage.gzsFlag = false;
              this.$message.error("您未加入任何工作室，无法访问");
            }
          }
        });
    },
    getUserInfo() {
      let that = this;
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/organBusiness/info"),
          method: "post",
      		  data: {},
        })
        .then(({ data }) => {
          if (data.data) {
            if (data.data.avatar) {
              that.$store.commit("common/updateImageUrl", data.data.avatar);
            }
            if (data.data.teamsManages && data.data.teamsManages.length) {
              that.$store.commit(
                "common/updateTeamsManages",
                data.data.teamsManages
              );
              that.$store.commit(
                "common/updateCurrentTeam",
                data.data.teamsManages[0]
              );
              localStorage.teamsManages = JSON.stringify(
                data.data.teamsManages
              );
              localStorage.currentTeam = data.data.teamsManages[0];
            }
          }
        });
    },
    // 储存表单信息
    setUserInfo() {
      // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
      // 如果没有勾选，储存的信息为空
      if (this.checked) {
        this.setCookie("account", this.dataForm.account);
        // base64加密密码
        let passWord = Base64.encode(this.dataForm.password);
        this.setCookie("password", passWord);
      } else {
        this.setCookie("account", "");
        this.setCookie("password", "");
      }
    },
    // 获取cookie
    getCookie(key) {
      if (document.cookie.length > 0) {
        var start = document.cookie.indexOf(key + "=");
        if (start !== -1) {
          start = start + key.length + 1;
          var end = document.cookie.indexOf(";", start);
          if (end === -1) end = document.cookie.length;
          return unescape(document.cookie.substring(start, end));
        }
      }
      return "";
    },
    // 保存cookie
    setCookie(cName, value, expiredays) {
      var exdate = new Date();
      exdate.setDate(exdate.getDate() + expiredays);
      document.cookie =
        cName +
        "=" +
        decodeURIComponent(value) +
        (expiredays == null ? "" : ";expires=" + exdate.toGMTString());
    },
    // 获取动态的登录页面数据
    getTeamsInfo() {
      let obj = {
        intParam2: 1,
        intParam1: 10,
        stringParam1: this.deptId,
      };
      this.$httpAes({
        url: this.$httpAes.adornUrl("/teamsManage/list"),
        method: "post",
        data: obj,
      }).then(({ data }) => {
        if (data.data && data.data.length) {
          this.teamsInfo = data.data && data.data.length ? data.data[0] : "";
          document.title =
            "登录-" + this.teamsInfo.teamsName + "-" + this.teamsInfo.deptName;
          if (this.teamsInfo.avatar) {
            this.setFavicon(this.teamsInfo.avatar);
          }

          this.getpic();
        }
      });
    },
    // 获取背景图片
    getpic() {
      this.loading = true;
      this.$http({
        url: this.$http.adornUrl("/sysPic/listPic"),
        method: "get",
        params: {
          actionId: this.deptId,
          picType: 32,
        },
      }).then(({ data }) => {
        if (data.status) {
          setTimeout(() => {
            this.loading = false;
          }, 500);

          if (data.data && data.data.length) {
            this.bigSrc = data.data[0].picUrl;
            // data.data && data.data.length
            //   ? data.data[0].picUrl
            //   : require("@/assets/img/login/bg3.png");
          } else {
            this.bigSrc = require("@/assets/img/login/bg3.png");
          }
        }
      });
    },
    setFavicon(src) {
      const link = document.querySelector("link[rel~='icon']");
      if (!link) {
        console.error("Could not find favicon link element");
        return;
      }
      link.href = src;
    },
  },
};
</script>

<style lang="scss">
.login-box {
  height: 100vh;
  // background: url("../../assets/img/login/bg.png") center center no-repeat;
  // background-size: cover;
  position: relative;
  .img-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .logo {
    position: absolute;
    top: 0.15625rem;
    left: 0.15625rem;
    .default-img {
      width: 0.88020833rem;
      // height: 30px;
    }
  }
  .page-title {
    font-size: 0.28645833rem;
    padding-top: 0.520833rem;
    color: #fff;
    display: flex;
    justify-content: center;
    font-family: FangSong;
    font-weight: bold;
    letter-spacing: 5px;
    text-shadow: 2px 5px 6px rgba(0, 110, 209, 0.7);
  }
  // .detail-con {
  //   display: flex;
  //   justify-content: space-between;
  //   // padding: 82px 152px 0 166px;
  //   padding: 268px 152px 0 166px;
  //   // padding: 33.5vh 19vh 0 20.75vh;
  //   .left-con {
  //     width: 749px;
  //     height: 581px;
  //     img {
  //       width: 100%;
  //       height: 100%;
  //     }
  //   }
  //   .form-con {
  //     width: 513px;
  //     height: 485px;
  //     margin-top: 29px;
  //     background: url("../../assets/img/login/form-bg.png") center center
  //       no-repeat;
  //     background-size: 100% 100%;
  //     padding: 78px 72px 100px 73px;
  //     .form-title {
  //       font-size: 22px;
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       padding-bottom: 35px;
  //     }
  //     .el-form-item {
  //       position: relative;
  //       margin-bottom: 26px;
  //       .el-input--prefix .el-input__inner {
  //         height: 44px;
  //       }
  //       .el-input__prefix {
  //         display: flex;
  //         width: 20px;
  //         height: 44px;
  //         justify-content: center;
  //       }
  //       img {
  //         width: 20px;
  //         height: 20px;
  //         vertical-align: middle;
  //       }
  //     }
  //     .submit-btn {
  //       width: 100%;
  //     }
  //   }
  // }
  .detail-con {
    display: flex;
    justify-content: space-between;
    // padding: 82px 152px 0 166px;
    // padding: 268px 152px 0 166px;
    padding: 1.395833rem 0.7923rem 0 0.864583rem;
    .left-con {
      width: 3.901042rem;
      height: 3.02604167rem;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .form-con {
      width: 2.671875rem;
      height: 2.526042rem;
      margin-top: 29px;
      background: url("../../assets/img/login/form-bg.png") center center
        no-repeat;
      background-size: 100% 100%;
      padding: 0.40625rem 0.375rem 0.520833rem 0.38020833rem;
      .form-title {
        font-size: 0.1145833rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0.18229167rem;
      }
      .el-form-item {
        position: relative;
        margin-bottom: 0.1354167rem;
        .el-form-item__content {
          line-height: 0.2291667rem;
        }
        .el-input__inner {
          height: 0.2291667rem;
          font-size: 0.07291667rem;
        }
        .el-input--prefix .el-input__inner {
          // height: .229167rem;
          padding-left: 0.15625rem;
        }
        .el-input__prefix {
          display: flex;
          width: 0.104167rem;
          height: 0.229167rem;
          justify-content: center;
        }
        img {
          width: 0.1041667rem;
          height: 0.1041667rem;
          vertical-align: middle;
        }
      }
      .remember-pwd {
        .el-checkbox {
          font-size: 0.072917rem;
          .el-checkbox__inner {
            width: 0.072917rem;
            height: 0.072917rem;
          }
          .el-checkbox__label {
            padding-left: 0.052083rem;
            line-height: 0.098958rem;
            font-size: 0.072917rem;
          }
          .el-checkbox__inner:after {
            border: 0.005208rem solid #fff;
            border-left: 0;
            border-top: 0;
            height: 0.036458rem;
            left: 0.020833rem;
            top: 0.005208rem;
            width: 0.015625rem;
          }
        }
      }
      .submit-btn {
        width: 100%;
        margin-top: 0.1041667rem;
        padding: 0.0625rem 0.104167rem;
        font-size: 0.072917rem;
        border-radius: 0.020833rem;
      }
    }
  }
}
.footer {
  width: 100%;
  color: #fff;
  text-align: center;
  position: fixed;
  bottom: 0.1041667rem;
  left: 0;
  font-size: 0.07291667rem;
  .support {
    padding-left: 0.41667rem;
    position: absolute;
  }
}
</style>
