import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import qs from 'qs'
import { clearLoginInfo } from '@/utils'
import { Message, Loading } from 'element-ui'
import Global from '@/utils/global'
import cryptoJs from 'crypto-js'


import { decrypt, encrypt, decryptPair, encryptPair, genKey } from "@/utils/aes";

let loading = {}
var num = 0 //记录请求数量 只打开一个loading
// 每次请求携带cookies信息
//axios.defaults.withCredentials =
//axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded';
const httpAes = axios.create({
  timeout: 1000 * 30,
  withCredentials: false,
  headers: {
    // 'Content-Type': 'application/x-www-form-urlencoded'
    'Content-Type': 'application/json;charset=UTF-8'
  }
})

/**
 * 请求拦截
 */
httpAes.interceptors.request.use(config => {
  console.log('请求拦截请求拦截请求拦截请求拦截')
  console.log(config.url)
  if (config.method == 'get') {
    Message.error({
      message: '请求方式异常'
    })
  } else {
    let data = config.data
    console.log(JSON.parse(JSON.stringify(data)))
    var aesKey = genKey();
    data = encrypt(data, aesKey);
    //前端RSA公钥
    var publicKey = "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCE66++2AZIZhbvwWcN+YqpNb8vX6R+hp9HiM/jwJjYGIC0tQbuWdeeh4FUHhKly65bxK0ysASzH2rvuRnWURPXc9rCZqTbD7gPYWK/FaTjRtNku0Xlg4BeWvOsoIGNkWKZxrOH7fKcaG4FZtvekDyVINOM67h1yAM1vX6/cnn7swIDAQAB";
    aesKey = encryptPair(aesKey, publicKey);
    //发送请求之前随机获取AES的key
    data = {
      data: data,//AES加密后的数据
      aesKey: aesKey,//后端RSA公钥加密后的AES的key
      publicKey: window.jsPublicKey//前端公钥
    };
    config.data = data
  }

  config.headers['token'] = localStorage.getItem('token') // 请求头带上token
  num++
  // openFullScreen()
  return config
}, error => {
  Message.error({
    message: '加载超时'
  })
  return Promise.reject(error);
})

/**
 * 响应拦截
 */
httpAes.interceptors.response.use(response => {
  num--
  // 判断所有请求是否结束
  if (num <= 0) {
    // 关闭loading
    // loading.close()
  }
  if (response.data.data != undefined && response.data.data.errorcode != undefined) {
    if (response.data.data.errorcode == '401' || response.data.data.errorcode == '404') { // 401, token失效
      clearLoginInfo()
      Message({
        showClose: true,
        message: response.data.msg,
        type: 'error',
        duration: 2000
      })
      router.push({ name: 'login' })
    } else if (response.data.data.errorcode == '500') {
      Message({
        showClose: true,
        message: '服务繁忙，请稍后再试',
        type: 'error',
        duration: 2000
      })
    }
  } else {
    console.log('响应拦截响应拦截响应拦截响应拦截响应拦截响应拦截')
    console.log(response)
    var mydata = response.data.body.data;
    var aesKey = response.data.body.aesKey;
    var privateKey = "MIICeAIBADANBgkqhkiG9w0BAQEFAASCAmIwggJeAgEAAoGBAOwNCZnVBtA4ADPFC+fJOnHvszXWkKZfL/lKqGzK6m6vQxnvm8W7vXHkUFjRclJvOxSB8TOpGOUWHV0rHRWIyJxRrZlDbD/IdF6a5vpBiakocqTmxS9EL59g1ZaeMpOeQC4x5+vpHL3x06BViumZSQoZ+/lQnPffKXYp3ZZhuctHAgMBAAECgYBIK1BFJRlZLdX5/gO+0Qo6bYPIMRq2kyGywT2vTXbRcmo8ksJ4eQbZvGlITnj6dSGGCph5J/kBiXpe0uCohdJ3SizbLpJ2l88KZ6LYvtQ1o8szEHqxyifeoEEWb2Yi2wwYsbdyw24JHct7oIXWrBiNWtKtPsC4HUdHuBIt4HXLiQJBAPz5+c5iroW4tTDRU61J+j36R6QVDwe6p7gVjp5GKklDphnjDntQ2la/NLoD9auOF7naoZzxd7wTY8q5pBP1ipsCQQDu30ZwU9JQf3TyLNkC9N8liZAnIeil6thN7TZibAS9x19aNiZotsnP/ecq7FFqBA9mC5iqBjOek9eiW7DAu4bFAkEA+wzgeY64/3+UYMP7tIcrzgHows8bQWJdO3Q5Op0LLfXyitIn9v0AEQJjww5W6U90AD8WD3gaiQz9BZxBVoVgbQJBAKkgyTzK/IQmWmUFv/lJ650mUzyB07l2GATEydbR6GF+glLbOEK3+RgdC8nmXJaVnVmBKGxpy66huvGnvfQYUokCQQD2CDFuzS/fX1N57EPUHB1HS1UgXwlQlXcWPSq5Jw6r1VkIIe7iEYh+w5V07Lb1uw5HebuErKAqrus+lUr282mF";
    var str = decryptPair(aesKey, privateKey);
    let obj = decrypt(mydata, str)
    console.log(obj)
    response.data = obj
    return response
  }


}, error => {
  // loading.close()
  if (error.response.status == 401) { // 401, token失效
    clearLoginInfo()
    // router.push({ name: 'login',query: {teamsId: localStorage.loginTeamId} })
  } else if (error.response.status == 500 || error.response.status == 504) {
    Message({
      showClose: true,
      message: '服务繁忙，请稍后再试',
      type: 'error',
      duration: 2000
    })
  }
  return Promise.reject(error)
})
/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
httpAes.adornUrl = (actionName) => {
  // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
  // console.log((process.env.NODE_ENV !== 'production' ? '/proxyApi' : window.SITE_CONFIG.baseUrl) + actionName)
  return Global.baseURL + actionName
}
/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
// httpAes.adornUrl = (actionName) => {
//   // 非生产环境 && 开启代理, 接口前缀统一使用[/proxyApi/]前缀做代理拦截!
//   return (process.env.NODE_ENV !== 'production' && process.env.OPEN_PROXY ? '/proxyApi/' : window.SITE_CONFIG.baseUrl) + actionName,
// 	
// }

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
httpAes.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {
    't': new Date().getTime()
  }
  return openDefultParams ? merge(defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
httpAes.adornData = (data = {}, openDefultdata = true, contentType = 'json') => {
  var defaults = {
    't': new Date().getTime()
  }
  data = openDefultdata ? merge(defaults, data) : data
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

function openFullScreen() {
  loading = Loading.service({
    lock: true,
    // text: "正在连接",
    // spinner: "el-icon-loading",
    background: "rgba(0, 0, 0, 0.7)"
  });
}

export default httpAes
