<template>
  <div class="navbar-wrapper">
    <div class="navbar-con">
      <div class="left-con">
        <div class="site-sidebar-name">
          <!-- <el-image
            class="avatar"
            :src="
              basicInfo.avatar ? basicInfo.avatar : '~@/assets/img/logo.png'
            "
            fit="conver"
          ></el-image> -->
          <span v-if="!sidebarFold" style="padding-left: 8px">{{ title }}</span>
        </div>
      </div>
      <div class="right-con">
        <div style="display: flex">
          <div class="teams-manage-list" v-if="showTeam">
            <span style="padding-right: 16px">{{
              currentTeam.entityName
            }}</span>
            <el-select
              class="custom-select"
              v-model="currentTeam"
              value-key="id"
              @change="changeCurrentTeam"
            >
              <el-option
                v-for="(item, index) in teamsManages"
                :key="index"
                :value="item"
                :label="item.teamsName"
                >{{ item.teamsName }}</el-option
              >
            </el-select>
          </div>
          <el-popover
            @hide="handleClose2()"
            placement="bottom"
            width="180"
            trigger="hover"
          >
            <div class="share-box">
              <div class="code-img">
                <!-- <div class="qrcode" id="qrCode2" ref="qrCodeDiv2"></div> -->
                <img
                  style="width: 110px; height: 110px"
                  :src="codeUrl"
                  alt=""
                />
              </div>
              <div class="share-box-text">邀请团队二维码</div>
            </div>
            <div
              v-show="gzsFlag"
              class="invite teams-manage-list"
              slot="reference"
            >
              <img class="code-icon" src="~@/assets/img/code-icon.png" />
              邀请团队
            </div>
          </el-popover>
          <el-popover
            @hide="handleClose()"
            placement="bottom"
            width="180"
            trigger="hover"
          >
            <div class="share-box">
              <div class="code-img">
                <!-- <div class="qrcode" id="qrCode" ref="qrCodeDiv"></div> -->
                <img
                  style="width: 110px; height: 110px"
                  :src="codeUrl2"
                  alt=""
                />
              </div>
              <div class="share-box-text">邀请患者二维码</div>
            </div>
            <div
              v-show="gzsFlag"
              class="invite teams-manage-list"
              slot="reference"
            >
              <img class="code-icon" src="~@/assets/img/code-icon.png" />
              邀请患者
            </div>
          </el-popover>
          <div class="invite2 teams-manage-list">
            <el-badge :value="chatNum" :hidden="chatNum == 0">
              <img
                @click="gotochat"
                class="code-icon"
                src="~@/assets/img/msg.png"
              />
            </el-badge>
          </div>
          <!-- <div class="invite teams-manage-list"  @mouseenter="showShareDialog2()" >
         <img class="code-icon" src="~@/assets/img/code-icon.png"/>
         邀请团队
          </div>-->
          <!--  <div class="invite teams-manage-list"  @mouseenter="showShareDialog()" >
         <img class="code-icon" src="~@/assets/img/code-icon.png"/>
         邀请患者
          </div>-->
          <el-menu
            class="site-navbar__menu site-navbar__menu--right"
            mode="horizontal"
            style="border-bottom: none"
          >
            <el-menu-item
              style="padding-left: 24px"
              class="site-navbar__avatar custom-dropdown"
              index="3"
            >
              <el-dropdown
                :show-timeout="0"
                placement="bottom"
                class="custom-dropdown"
              >
                <span class="el-dropdown-link" style="color: white">
                  <img
                    class="avatar-size"
                    v-if="imgUrl == null || imgUrl == ''"
                    src="~@/assets/img/headerImg.png"
                  />
                  <img v-else :src="imgUrl" />
                  {{ userName }}
                </span>
                <img class="down" src="../assets/img/down.png" alt="" />
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="gotoAccount()"
                    >账户信息</el-dropdown-item
                  >
                  <el-dropdown-item @click.native="pwdChange()"
                    >修改密码</el-dropdown-item
                  >
                  <!-- <el-dropdown-item
                    v-if="flag && gzsFlag"
                    @click.native="changeRole()"
                    >切换角色</el-dropdown-item
                  > -->
                  <el-dropdown-item @click.native="logoutHandle()"
                    >退出</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </el-menu-item>
          </el-menu>
        </div>
      </div>
    </div>

    <div class="nav-content">
      <div class="breadcrumb">
        <el-breadcrumb
          style="height: 40px; line-height: 40px"
          separator="/"
          v-if="$route.meta.remark == 2"
        >
          <el-breadcrumb-item
            v-for="(item, index) in $route.meta.title"
            :to="
              index == 1
                ? {
                    name: 'indicatorInfo',
                    query: { id: quesitonId, activeName: activeName },
                  }
                : { name: 'dataCollect' }
            "
            :key="index"
            >{{ item.name }}</el-breadcrumb-item
          >
        </el-breadcrumb>
        <el-breadcrumb
          style="height: 40px; line-height: 40px"
          separator="/"
          v-if="$route.meta.isDynamic"
        >
          <el-breadcrumb-item :to="{ name: 'queue' }">队列研究</el-breadcrumb-item>
          <el-breadcrumb-item>{{$route.query.studyName}}队列设置</el-breadcrumb-item>
        </el-breadcrumb>
        <el-breadcrumb
          v-else
          style="height: 40px; line-height: 40px"
          separator="/"
        >
          <el-breadcrumb-item
            v-for="(item, index) in $route.meta.title"
            :to="{ name: item.linkUrl }"
            :key="index"
            >{{ item.name }}</el-breadcrumb-item
          >
        </el-breadcrumb>
      </div>
    </div>
    <el-dialog title="切换角色" :visible.sync="roleDialogVisible" width="400px">
      <div class="role-change">
        <div
          class="role-item"
          :class="{ active: radio == 1 }"
          @click="changeMenu('1')"
        >
          <div class="img-box">
            <img src="~@/assets/img/role-1.png" />
          </div>
          <div class="role-name">运营人员</div>
        </div>
        <div
          class="role-item"
          style="margin-left: 76px"
          :class="{ active: radio == 2 }"
          @click="changeMenu('2')"
        >
          <div class="img-box">
            <img src="~@/assets/img/role-2.png" />
          </div>
          <div class="role-name">团队成员</div>
        </div>
      </div>
    </el-dialog>
    <!--    <el-dialog
		  title="邀请二维码"
		  :close-on-click-modal="false"
		  :visible.sync="shareDialog"
		  :before-close="handleClose"
		  width="250px"
		>
		  <div class="share-box">
		
		    <div class="code-img">
		      <div class="qrcode" id="qrCode" ref="qrCodeDiv"></div>
		    </div>
		  

		  </div>
    </el-dialog>-->
  </div>
</template>

<script>
import Qs from "qs";
import Bus from "@/utils/bus";
import QRCode from "qrcodejs2";

import { clearLoginInfo } from "@/utils";
import { getMenuList, shortMenuList } from "../utils/menu";
export default {
  data() {
    return {
      collapseFlag: true,
      deptName: "",
      chatNum: "",
      codeUrl: "",
      codeUrl2: "",
      imageUrl: "", //医院logo
      updatePassowrdVisible: false,
      currentTeam: "", //当前选中的团队
      teamsManages: [],
      roleDialogVisible: false,
      flag: false,
      showTeam: false,
      gzsFlag: false,
      shareDialog: false,
      radio: "2",
      title: "",
      basicInfo: {}, //平台基本信息
    };
  },

  computed: {
    // teamsManages: {
    //   get() {
    //     if (localStorage.teamsManages) {
    //       return JSON.parse(localStorage.teamsManages);
    //     } else {
    //        return this.$store.state.common.teamsManages;
    //     }
    //   },
    //   set(val) {
    //     this.$store.commit("common/updateTeamsManages", val);
    //   }
    // },
    remark() {
      if (this.$route.meta.remark) {
        return this.$route.meta.remark;
      }
    },
    quesitonId() {
      if (this.$route.query.id) {
        return this.$route.query.id;
      }
    },
    activeName() {
      if (this.$route.query.activeName) {
        return this.$route.query.activeName;
      }
    },
    userName() {
      if (localStorage.getItem("userInfo")) {
        let user = JSON.parse(localStorage.getItem("userInfo"));
        return user.name;
      }
    },
    infoId() {
      if (this.$route.query.id) {
        return this.$route.query.id;
      }
    },
    menuActiveName: {
      get() {
        return this.$store.state.common.menuActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMenuActiveName", val);
      },
    },
    userCount: {
      get() {
        return this.$store.state.common.userCount;
      },
      set(val) {
        this.$store.commit("common/updateUserCount", val);
      },
    },
    total: {
      get() {
        return this.$store.state.common.total;
      },
      set(val) {
        this.$store.commit("common/updateTotal", val);
      },
    },
    navbarLayoutType: {
      get() {
        return this.$store.state.common.navbarLayoutType;
      },
    },
    sidebarFold: {
      get() {
        return this.$store.state.common.sidebarFold;
      },
      set(val) {
        this.$store.commit("common/updateSidebarFold", val);
      },
    },
    imgUrl: {
      get() {
        return this.$store.state.common.imageUrl;
      },
      set(val) {
        this.$store.commit("common/updateImageUrl", val);
      },
    },
    accountInfoFlag: {
      get() {
        return this.$store.state.common.accountInfoFlag;
      },
      set(val) {
        this.$store.commit("common/updateAccountInfoFlag", val);
      },
    },
    pwdChangeFlag: {
      get() {
        return this.$store.state.common.pwdChangeFlag;
      },
      set(val) {
        this.$store.commit("common/updatePwdChangeFlag", val);
      },
    },
    mainTabsActiveName: {
      get() {
        return this.$store.state.common.mainTabsActiveName;
      },
      set(val) {
        this.$store.commit("common/updateMainTabsActiveName", val);
      },
    },
    menuList: {
      get() {
        return this.$store.state.common.menuList;
      },
      set(val) {
        this.$store.commit("common/updateMenuList", val);
      },
    },
    // radio: {
    //   get() {
    //     return this.$store.state.common.roleRadio;
    //   },
    //   set(val) {
    //     this.$store.commit("common/updateRoleRadio", val);
    //   }
    // },
    radioFlag: {
      get() {
        return sessionStorage.radio;
      },
      set(val) {
        this.$store.commit("common/updateRoleRadio", val);
      },
    },
  },
  mounted() {
    if (localStorage.getItem("userInfo")) {
      let user = JSON.parse(localStorage.getItem("userInfo"));
      if (user.roleId == "e89325119fa411e89e43525400557296") {
        this.flag = true;
      } else {
        this.flag = false;
      }
      setTimeout(() => {
        console.log(
          "this.getForeverStrTicket(1)this.getForeverStrTicket(1)this.getForeverStrTicket(1)this.getForeverStrTicket(1)"
        );
        this.getForeverStrTicket(1);

        this.getForeverStrTicket(2);
      }, 1000);
      // this.$store.commit("common/updateImageUrl", uploadImg() + imgUrl.avatar);
    }
    this.getChatNum();
    this.getUserInfo();
    if (localStorage.getItem("login") == "login") {
      this.title = "专家数字孪生系统";
    } else if (localStorage.getItem("login") == "zdLogin") {
      this.title = "东南大学附属中大医院肾脏病诊治中心数据库与病例队列系统";
    } else if (localStorage.getItem("login") == "tnbLogin") {
      this.title = "悟糖实时血糖管理平台";
    } else if (localStorage.getItem("login") == "kfskLogin") {
      this.title = "康复时空健康管理";
    }
    if (sessionStorage.radio) {
      this.radio = sessionStorage.radio;
    }
    // if (sessionStorage.radio != undefined) {
    //   this.radio = sessionStorage.radio;
    //   if (this.radio == "1") {
    //     this.menuList = getMenuList2();
    //     this.roleDialogVisible = false;
    //     if (flag == "1") {
    //       this.$router.push({
    //         name: "medicine/medicineList",
    //       });
    //     }
    //     this.$forceUpdate();
    //   } else {
    //     this.menuList = getMenuList();
    //     this.roleDialogVisible = false;
    //     if (flag == "2") {
    //       this.$router.push({
    //         name: "home",
    //       });
    //     }
    //     this.$forceUpdate();
    //   }
    // }

    // this.changeMenu();
    this.getList();
  },
  methods: {
    gotochat() {
      let routerUrl = this.$router.resolve({
        name: "chat",
      });
      window.open(routerUrl.href, "_blank");
    },
    showShareDialog() {
      // this.shareDialog = true;
      // let row = JSON.parse(localStorage.getItem("currentTeam"));
      // let user = JSON.parse(localStorage.getItem("userInfo"));

      //正式
      //  this.url =this.$global.dominUrl + "/patient/index.html#/login?teamsId=" + row.id+'&doctorId='+ JSON.parse(localStorage.userInfo).id
      //测试
      // this.url =
      //   "https://ky.docteams.cn/devpatient/#/login?teamsId=" +
      //   row.id +
      //   "&doctorId=" +
      //   JSON.parse(localStorage.userInfo).id +
      //   "&name=" +
      //   row.teamsName +
      //   "&doctorName=" +
      //   user.name;

      // console.log(this.url);

      // this.$nextTick(function() {
      //   this.bindQRCode();
      // });
      this.getForeverStrTicket(1);
    },
    showShareDialog2() {
      this.getForeverStrTicket(2);
      // let row = JSON.parse(localStorage.getItem("currentTeam"));
      // let user = JSON.parse(localStorage.getItem("userInfo"));

      // // this.url =this.$global.dominUrl2 + "qzq-doctor/index.html#/?teamsId=" + row.id+'&entityId='+row.entityId;
      // // this.url =this.$global.dominUrl2 + "doctor/index.html#/?teamsId=" + row.id+'&entityId='+row.entityId;
      // this.url =
      //   "https://ky.docteams.cn/devdoctor/#/login?teamsId=" +
      //   row.id +
      //   "&doctorId=" +
      //   JSON.parse(localStorage.userInfo).id +
      //   "&name=" +
      //   row.teamsName +
      //   "&doctorName=" +
      //   user.name;

      // console.log(this.url);

      // this.$nextTick(function() {
      //   this.bindQRCode2();
      // });
    },
    bindQRCode(url) {
      //获取二维码
      let that = this;
      that.qrcode = new QRCode("qrCode", {
        text: url,
        width: 110,
        height: 110,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    bindQRCode2(url) {
      //获取二维码
      let that = this;
      that.qrcode = new QRCode("qrCode2", {
        text: url,
        width: 110,
        height: 110,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    handleClose() {
      this.shareDialog = false;
      document.getElementById("qrCode").innerHTML = "";
    },
    handleClose2() {
      this.shareDialog = false;

      document.getElementById("qrCode2").innerHTML = "";
    },
    changeMenu(flag) {
      console.log(this.radio);
      this.radio = flag;
      if (this.radio == "1") {
        sessionStorage.radio = "1";
        this.menuList = shortMenuList();
        this.roleDialogVisible = false;
        if (flag == "1") {
          this.$router.push({
            name: "medicine/medicineList",
          });
        }
        this.$forceUpdate();
      } else {
        sessionStorage.radio = "2";
        this.menuList = getMenuList();
        this.roleDialogVisible = false;
        if (flag == "2") {
          this.$router.push({
            name: "home",
          });
        }
        this.$forceUpdate();
      }
    },
    changeRole() {
      this.roleDialogVisible = true;
    },
    getForeverStrTicket(type) {
      let params = {
        sceneStr: "",
      };
      if (type == 2) {
        params.sceneStr = JSON.parse(localStorage.getItem("currentTeam")).id;
      } else {
        params.sceneStr =
          JSON.parse(localStorage.getItem("currentTeam")).id +
          JSON.parse(localStorage.userInfo).id;
      }

      this.$http({
        url: this.$http.adornUrl("/weiXinQRCode/getForeverStrTicket"),
        method: "get",
        params: params,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",

          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        if (type == 1) {
          this.codeUrl2 =
            "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" + data.data;
          //	this.bindQRCode('https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='+data.data)
        } else {
          this.codeUrl =
            "https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=" + data.data;
          //	this.bindQRCode2('https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket='+data.data)
        }
      });
    },
    // 获取团队下的病种
    getUserInfo() {
      let that = this;
     that
       .$httpAes({
         url: that.$httpAes.adornUrl("/organBusiness/info"),
         method: "post",
     		  data: {},
        })
        .then(({ data }) => {
          if (data.data) {
            if (data.data.avatar) {
              that.$store.commit("common/updateImageUrl", data.data.avatar);
            }
            if (data.data.teamsManages && data.data.teamsManages.length) {
              that.teamsManages = data.data.teamsManages;
              localStorage.teamsManages = JSON.stringify(that.teamsManages);
              if (data.data.lastTeamId != null && data.data.lastTeamId != "") {
                console.log("data.data.lastTeamId != null");
                // that.currentTeam = that.teamsManages.find((item) => {
                //   return item.id == data.data.lastTeamId;
                // });
                that.currentTeam = JSON.parse(localStorage.currentTeam);
              } else {
                that.currentTeam = data.data.teamsManages[0];
                console.log(" that.currentTeam", that.currentTeam);
                //localStorage.currentTeam = JSON.stringify(data.data.teamsManages[0]);
              }
              this.showTeam = true;
              this.gzsFlag = true;

              localStorage.gzsFlag = true;
            }
            // else if (data.data.roleId == "e89325119fa411e89e43525400557296") {
            //   console.log("没工作室+管理员身份");
            //   localStorage.gzsFlag = false;
            //   this.menuList = shortMenuList();
            //   this.$router.push({
            //     name: "teamList",
            //   });
            // }
            else if (data.data.roleId != "e89325119fa411e89e43525400557296") {
              localStorage.gzsFlag = false;
              this.$message.error("您未加入任何工作室，无法访问");
              if (sessionStorage.loginType == "zdLogin") {
                this.$router.push({
                  name: "zdLogin",
                });
              } else {
                this.$router.push({
                  name: "login",
                });
              }
            }
          }
        });
    },

    getChatNum() {
      let that = this;
      let params = {};
      if (localStorage.currentTeam) {
        params = {
          stringParam1:
            JSON.parse(localStorage.userInfo).id.substring(0, 16) +
            JSON.parse(localStorage.currentTeam).id.substring(0, 16),
        };
      }

      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/bind/getC2cUnreadMsgNum"),
          method: "post",
          data:params,
        })
        .then(({ data }) => {
          if (data.status) {
            that.chatNum = JSON.parse(data.data).AllC2CUnreadMsgNum;
          }
        });
    },

    changeCurrentTeam(val) {
      // this.$store.commit("common/updateCurrentTeam", val);
      localStorage.currentTeam = JSON.stringify(val);
      let organBusinessInfo = JSON.parse(sessionStorage.organBusinessInfo);
      let that = this;
      let params = {
        id: organBusinessInfo.id,
        idPic: organBusinessInfo.idPic,
        certificatePic: organBusinessInfo.certificatePic,
        licensePic: organBusinessInfo.licensePic,
        workPic: organBusinessInfo.workPic,
        technicalPic: organBusinessInfo.technicalPic,
        auditStatus: organBusinessInfo.auditStatus,
        auditContext: organBusinessInfo.auditContext,
        lastTeamId: val.id,
      };
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/organBusiness/updateById"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            that.$message.success("切换成功");
            this.getTeamsMember();
            that.$nextTick(() => {
              that.$router.go(0);
            });
            setTimeout(() => {
              this.getForeverStrTicket(1);

              this.getForeverStrTicket(2);
            }, 500);
          }
        });
      //location.reload();
    },
    async getTeamsMember() {
      let _this = this;
      let params = {
        intParam2: 1,
        intParam1: 10,
        stringParam6: "",
        stringParam5: JSON.parse(localStorage.currentTeam).id,
        stringParam3: JSON.parse(localStorage.userInfo).name,
      };
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/teamsMember/list"),
        method: "post",
        data: params,
      });
      console.log(res.data);
      if (res.status && res.data && res.data.length) {
        localStorage.currentTeamRoleId = res.data[0].roleIds;
      }
    },
    changeSidebarFold() {
      this.collapseFlag = !this.collapseFlag;
      if (this.sidebarFold) {
        this.$store.commit("common/updateSidebarFold", false);
      } else {
        this.$store.commit("common/updateSidebarFold", true);
      }
    },
    goHome() {
      this.$router.push({ name: "home" });
      this.$store.commit("common/updateMenuActiveName", "home");
    },
    goMzjl() {
      this.$router.push({ name: "mzjl" });
      this.$store.commit("common/updateMenuActiveName", "");
    },
    // 获取医院数据
    getHospitalInfo() {
      let that = this;
      let dept = JSON.parse(that.$cookie.get("dept"));
      let params = {
        deptId: dept.id,
      };
      that
        .$http({
          url: that.$http.adornUrl("/deptSetting/info"),
          method: "get",
          params: params,
        })
        .then(({ data }) => {
          if (data.status) {
            that.imageUrl = data.data.loginLogo;
          } else {
            that.$message.error(data.msg);
          }
        });
    },
    // 修改密码
    tabsCloseAllHandle() {
      Bus.$emit("getTarget");
    },
    gotoPc() {
      window.open("https://fn.qkyxzx.com/");
    },
    updatePasswordHandle() {
      this.updatePassowrdVisible = true;
      this.$nextTick(() => {
        this.$refs.updatePassowrd.init();
      });
    },

    gotoAccount() {
      this.$router.push({ path: "/account/account-info" });
      this.$store.commit("common/updateMenuActiveName", "account-info");
    },
    pwdChange() {
      this.$router.push({ path: "/account/pwd-change" });
      this.$store.commit("common/updateMenuActiveName", "pwd-change");
    },
    // 退出
    logoutHandle() {
      this.$confirm(`确定进行[退出]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          clearLoginInfo();
          //localStorage.removeItem('menuList')
          if (sessionStorage.loginType == "zdLogin") {
            this.$router.push({
              name: "zdLogin",
            });
          } else {
            if (localStorage.loginTeamId) {
              this.$router.push({
                name: "login",
                query: { teamsId: localStorage.loginTeamId },
              });
            } else {
              this.$router.push({
                name: "login",
              });
            }
          }
          // this.$router.push({ name: localStorage.getItem('login')});
        })
        .catch(() => {});
    },
    getList() {
      // this.$http({
      //       url: this.$http.adornUrl("/sysPic/listPic"),
      //       method: "get",
      //       params: {
      //         actionId: '14d585b6fb3c43d3a2efed677e986de4',
      //         picType:20,
      //         pageNum:1,
      //         pageSize:999
      //       }
      //     })
      this.$httpAes({
        url: this.$httpAes.adornUrl("/teamsManage/list"),
        method: "post",
		  data: {
          stringParam1: JSON.parse(localStorage.currentTeam).id,
        },
        // params: {
        //   id: JSON.parse(localStorage.currentTeam).id,
        // },
      }).then((res) => {
        if (res.data.status && res.data.data && res.data.data.length) {
          this.basicInfo = res.data.data[0];
          this.title = this.basicInfo.teamsName;
          document.title =
            this.basicInfo.teamsName + "-" + this.basicInfo.deptName;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-menu-item:hover {
  background-color: transparent !important;
}

.navbar-top {
  display: flex;
  flex-direction: row-reverse;
  height: 56px;
  // border-bottom: 1px solid #eee;
  background: linear-gradient(90deg, #3eaaff 0%, #8dbfff 100%);
}

.site-navbar__menu .el-menu-item,
.site-navbar__menu .el-submenu > .el-submenu__title {
  height: 60px;
  line-height: 60px;
}
.site-navbar__menu.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
  background: none;
}
.site-navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  height: 100px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);
  background-color: #fff;
  padding-left: 230px;
  overflow: hidden;
  &.fold {
    padding-left: 80px;
  }
}
.site-navbar__header {
  position: relative;
  float: left;
  height: 60px;
  padding-right: 80px;
}
.site-navbar__brand {
  font-size: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
}

.site-navbar__menu .el-menu-item,
.site-navbar__menu .el-submenu > .el-submenu__title {
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0 48px;
}
.site-navbar__avatar .el-dropdown-link > img {
  width: 28px;
  height: 28px;
  margin-right: 5px;
  border-radius: 100%;
  vertical-align: middle;
}
.el-menu-item [class^="el-icon-"] {
  margin-right: 5px;
  width: 35px;
  text-align: center;
  font-size: 35px;
  vertical-align: middle;
}
// 3-12
.right-con {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  align-items: center;
}
.right-con {
  img {
    width: 23px;
    height: 20px;
  }

  .code-icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .down {
    width: 12px;
    height: 12px;
    margin-left: 4px;
  }
}
.avatar-size {
  width: 28px;
  height: 28px;
}
.parent-menu {
  width: 300px;
  height: 60px;
  font-size: 20px;
  display: flex;
  .menu-item {
    padding: 0 40px;
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.teams-manage-list {
  color: white;
  display: flex;
  align-items: center;
}
.invite {
  height: 56px;
  cursor: pointer;
  margin-left: 48px;
}
.invite2 {
  height: 56px;
  cursor: pointer;
  margin-left: 24px;
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover {
  background-color: transparent !important;
}
.navbar-wrapper {
  .navbar-con {
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 1100;
    top: 0;
    left: 0;
    background: linear-gradient(90deg, #3eaaff 0%, #8dbfff 100%);
    .site-sidebar-name {
      height: 56px;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 22px;
      padding-left: 16px;
      font-weight: 500;
    }
  }
}
.nav-content {
  height: 100px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  background: #fff;
  padding: 56px 0 0 208px;
  .breadcrumb {
    // padding-left: 160px;
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 20px;
  }
}
.role-change {
  display: flex;
  justify-content: center;
  .role-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &.active {
      .img-box {
        border: 1px solid #0099ff;
      }
      .role-name {
        color: #0099ff;
      }
    }
  }
  .img-box {
    width: 98px;
    height: 98px;
    border-radius: 50%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 55px;
      height: 62px;
    }
  }
  .role-name {
    display: inline-block;
    padding-top: 10px;
  }
}
.share-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 10px;
  .share-box-text {
    padding-top: 5px;
    color: #666;
  }
}
.avatar {
  width: 24px;
  height: 24px;
  border-radius: 24px;
  img {
    width: 24px;
    height: 24px;
    border-radius: 12px;
  }
}
</style>
