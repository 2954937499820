<template>
  <el-dialog title="设置分组" :visible.sync="visible" width="800px">
    <div class="setting-group">
      <div>确认将所选的用户的分组设置为：</div>
      <div class="group-name-list">
        <div
          class="item"
          v-for="(item, index) in groupList"
          :class="{ active: item.checked }"
          :key="index"
          @click="clickItem(item, index)"
        >
          {{ item.labelName }}
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="confirm">确认</el-button>
      <el-button @click="visible = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      groupList: [],
      choseIds: "",
      type: "",
    };
  },
  methods: {
    init(ids, list, type, row) {
      this.visible = true;
      this.choseIds = ids;
      this.type = type;
      let obj = row && row.id ? JSON.parse(JSON.stringify(row)) : {};
      list.forEach((ele) => {
        ele.checked = false;
      });
      let arr = [];
      if (type == 2 && obj.grouping_id) {
        arr = obj.grouping_id.split(",");
        arr.forEach((ele) => {
          list.forEach((chr) => {
            if (ele == chr.id) {
              chr.checked = true;
            }
          });
        });
      }
      if (type == 1 && obj.label_id) {
        arr = obj.label_id.split(",");
        arr.forEach((ele) => {
          list.forEach((chr) => {
            if (ele == chr.id) {
              chr.checked = true;
            }
          });
        });
      }
      this.groupList = JSON.parse(JSON.stringify(list));
    },
    clickItem(item, index) {
      item.checked = !item.checked;
      this.$forceUpdate();
    },
    async confirm() {
      let arr = [];
      this.groupList.forEach((chr) => {
        if (chr.checked) {
          arr.push(chr.id);
        }
      });
      if (arr.length == 0) {
        if (this.type == 2) {
          this.$message.error("请选择分组名称");
        }
        if (this.type == 1) {
          this.$message.error("请选择标签名称");
        }
        return;
      }
      let params = {
        // type: this.type,
        stringParam1: this.choseIds,
      };
      if (this.type == 2) {
        params.stringParam3 = arr.join(",");
      }
      if (this.type == 1) {
        params.stringParam2 = arr.join(",");
      }
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/teamsPatience/updateLabelByIds"),
        method: "post",
        data: params,
      });
      if (res.status) {
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
          onClose: () => {
            this.visible = false;
            this.$emit("refreshData");
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.group-name-list {
  overflow: hidden;
  .item {
    width: auto;
    height: 30px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin-right: 8px;
    float: left;
    margin-top: 18px;
    border: 1px solid #dddddd;
    color: #999999;
    cursor: pointer;
    &.active {
      background: #009cff;
      border: none;
      color: #fff;
    }
  }
}
</style>
