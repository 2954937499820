import { render, staticRenderFns } from "./sendPackage.vue?vue&type=template&id=53e3638e&scoped=true"
import script from "./sendPackage.vue?vue&type=script&lang=js"
export * from "./sendPackage.vue?vue&type=script&lang=js"
import style0 from "./sendPackage.vue?vue&type=style&index=0&id=53e3638e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53e3638e",
  null
  
)

export default component.exports