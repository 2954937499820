<template>
  <el-dialog
    title="分配方案"
    :visible.sync="followUpDialog"
    width="800px"
    :close-on-click-modal="false"
  >
    <div class="follow-up-text-tip">确认将所选的用户的分配下述方案：</div>
    <el-form
      :model="addForm"
      label-width="120px"
      :rules="addDataRules"
      ref="addForm"
      class="dialog-form-con"
    >
      <el-form-item label="随访方案" prop="programId">
        <el-select
          placeholder="请选择"
          v-model="addForm.programId"
          clearable
          style="width: 350px"
          filterable=""
        >
          <el-option
            v-for="(item, index) in programList"
            :key="index"
            :value="item.id"
            :label="item.programName"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="开始执行时间" prop="executionTime">
        <el-date-picker
          style="width: 350px"
          v-model="addForm.executionTime"
          type="date"
          placeholder="请选择"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        ></el-date-picker>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="isExistExecutionPlan">确定</el-button>
      <el-button @click="followUpDialog = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import moment from 'moment';
export default {
  data() {
    return {
      followUpDialog: false,
      addForm: {
        programId: "",
        executionTime: moment(new Date()).format('YYYY-MM-DD'),
      },
      addDataRules: {
        executionTime: [
          {
            required: true,
            message: "请选择执行时间",
            trigger: "blur",
          },
        ],
        programId: [
          { required: true, message: "请选择方案名称", trigger: "change" },
        ],
      },
      programList: [],
      flag: true,
      patientIds: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },

    };
  },
  methods: {
    init(ids) {
      this.followUpDialog = true;
      this.getProgramNameList();
      this.patientIds = ids;
      this.$nextTick(()=>{
        this.$refs.addForm.resetFields();
      })
    },
    // 获取方案名称
    getProgramNameList() {
      let _this = this;
      let currentTeamId = "";
      if (localStorage.currentTeam) {
        currentTeamId = JSON.parse(localStorage.currentTeam).id;
      }
      let params = {
        pageSize: 10000,
        pageNo: 1,
        teamsId: currentTeamId,
      };
      _this
        .$http({
          url: _this.$http.adornUrl("/programInfo/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            _this.programList = data.data;
          }
        });
    },
    //点击判断是否存在执行的方案
    isExistExecutionPlan() {
      let _this = this;
      if (localStorage.currentTeam) {
        _this.addForm.teamId = JSON.parse(localStorage.currentTeam).id;
        _this.addForm.enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      _this.addForm.patienceId = _this.patientIds;
      if (localStorage.getItem("userInfo")) {
        this.addForm.hospitalId = JSON.parse(
          localStorage.getItem("userInfo")
        ).deptPid;
        this.addForm.deptId = JSON.parse(
          localStorage.getItem("userInfo")
        ).deptId;
      }
      _this.programList.forEach((ele) => {
        if (ele.id == _this.addForm.programId) {
          _this.addForm.programName = ele.programName;
        }
      });
      _this
        .$httpAes({
          url: _this.$httpAes.adornUrl("/patienceProgram/checkRepeat"),
          method: "post",
          data: _this.addForm,
        })
        .then(({ data }) => {
          if (data.status) {
            this.confirmSend();
          } else {
            this.$confirm(
              "当前病例中已有执行中的所选方案，是否继续分配？",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonClass: "btn-custom-cancel",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(async () => {
                this.confirmSend();
              })
              .catch(() => {});
          }
        });
    },
    // 确认发送
    confirmSend() {
      let _this = this;
      if (localStorage.currentTeam) {
        _this.addForm.teamId = JSON.parse(localStorage.currentTeam).id;
        _this.addForm.enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      _this.addForm.patienceId = _this.patientIds;
      if (localStorage.getItem("userInfo")) {
        this.addForm.hospitalId = JSON.parse(
          localStorage.getItem("userInfo")
        ).deptPid;
        this.addForm.deptId = JSON.parse(
          localStorage.getItem("userInfo")
        ).deptId;
      }
      _this.programList.forEach((ele) => {
        if (ele.id == _this.addForm.programId) {
          _this.addForm.programName = ele.programName;
        }
      });
      console.log(this.addForm);
      // return;
      _this.$refs["addForm"].validate((valid) => {
        if (valid) {
          if (_this.flag) {
            _this.flag = false;
            _this
              .$httpAes({
                url: _this.$httpAes.adornUrl("/patienceProgram/add"),
                method: "post",
                data: _this.addForm,
              })
              .then(({ data }) => {
                if (data.status) {
                  _this.$message({
                    message: data.msg,
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      _this.followUpDialog = false;
                      _this.flag = true;
                      _this.$emit("refreshData");
                    },
                  });
                } else {
                  _this.$message.error(data.msg);
                  _this.flag = true;
                }
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.follow-up-text-tip {
  height: 38px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  background: #e9f7ff;
  margin-bottom: 24px;
}
</style>
